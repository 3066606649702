import { GENDER_MALE, TYPE_PHOTO, TYPE_VIDEO } from "shared/constants";
import {
  EVENT_REG_METHOD_COR,
  EVENT_REG_METHOD_GOOGLE,
  EVENT_REG_METHOD_MAIL,
  GTM_AUTO,
  GTM_CHAT_FIRST,
  GTM_CHAT_MSG,
  GTM_CHAT_MSG_FAIL,
  GTM_CHAT_PHOTO,
  GTM_CHAT_PHOTO_FAIL,
  GTM_CHAT_VIDEO,
  GTM_CHAT_VIDEO_FAIL,
  GTM_DEL_FAVORITE,
  GTM_FAILED_PURCHASE,
  GTM_FAVORITE,
  GTM_LIKE,
  GTM_LOGIN,
  GTM_MAIL_OPEN,
  GTM_MAIL_SEND,
  GTM_MAIL_SEND_FAIL,
  GTM_OPEN_CHAT,
  GTM_REGISTRATION,
  GTM_REGISTRATION_FAIL,
  GTM_SEX_MALE,
  GTM_STICKER_SEND,
  GTM_STICKER_SEND_FAIL,
  GTM_SUBSCRIBE_ON_STREAM,
  GTM_SUCCESS_PURCHASE,
  GTM_VIEW_USER,
  GTM_VIEW_USER_PHOTO,
  GTM_VIEW_USER_VIDEO,
} from "shared/constants/analytics";
import {
  CHAT_MESSAGE_TYPE_GIFT,
  CHAT_MESSAGE_TYPE_MEDIA,
  CHAT_MESSAGE_TYPE_WINK,
} from "shared/constants/chat";
import { CHAT_FIRST_MESSAGE_COOKIE } from "shared/constants/cookie";
import { paymentCBType } from "shared/constants/payment";

import { getChatOpponentUidFromIdentity } from "./chat";
import {
  existCookie,
  getCookie,
  getUserSimpleCookieData,
  setCookie,
} from "./cookie";
import { hasTextEmoji } from "./emoji";
import { gtmTrack, isTrue } from "./view";

/**
 * Send success registration info to google teg manager
 * @param {Object} data Event data
 * @param {boolean} isGoogle Is google sign up
 * @param {boolean} isCoReg Is registration by remarketing
 */
export const registrationEvent = (data, isGoogle = false, isCoReg = false) => {
  simplyClick("cl_reg_btn_success", null, true);

  if (!data?.isGtmTrack) return;

  // const paramsBing = {
  //   userId: data.uid,
  //   event: "registration_bing",
  // };
  const paramsGoogle = JSON.parse(JSON.stringify(GTM_REGISTRATION));
  paramsGoogle.userId = data.uid;
  paramsGoogle.age = data?.age ?? 0;
  paramsGoogle.sex = data?.gender === GENDER_MALE ? "male" : "female";
  paramsGoogle.autoEventLabel = isGoogle
    ? EVENT_REG_METHOD_GOOGLE
    : isCoReg
      ? EVENT_REG_METHOD_COR
      : EVENT_REG_METHOD_MAIL;

  gtmTrack(GTM_AUTO, paramsGoogle);
  // window?.dataLayer?.push(paramsBing);
};

/**
 * Failed registration event
 * @param {boolean} isGoogle Is google sign up
 */
export const registrationFailedEvent = (isGoogle = false) => {
  const paramsGoogle = JSON.parse(JSON.stringify(GTM_REGISTRATION_FAIL));
  paramsGoogle.autoEventLabel = isGoogle
    ? EVENT_REG_METHOD_GOOGLE
    : EVENT_REG_METHOD_MAIL;

  gtmTrack(GTM_AUTO, paramsGoogle);

  simplyClick("cl_reg_btn_fail", null, false);
};

/**
 * Success login event
 * @param {{uid: number, url: string}} data Event data. Uid - user id, url - link where user will send after success login
 */
export const loginEvent = (data) => {
  const paramsGoogle = JSON.parse(JSON.stringify(GTM_LOGIN));
  paramsGoogle.userId = data?.uid ?? 0;
  paramsGoogle.autoEventLabel = data?.url ?? "";

  gtmTrack(GTM_AUTO, paramsGoogle);
};

/**
 * View user profile event
 * @param {{uid: number}} data Event data. Uid - viewed
 */
export const userProfileViewEvent = (data) => {
  const paramsGoogle = JSON.parse(JSON.stringify(GTM_VIEW_USER));
  paramsGoogle.profile_id = data.uid ?? 0;
  paramsGoogle.items = [{ item_name: paramsGoogle.profile_id }];

  gtmTrack(GTM_AUTO, paramsGoogle);
};

/**
 * View user image event
 * @param {{uid: number}} data Event data. Uid - viewed
 */
export const userImageViewEvent = (data) => {
  const paramsGoogle = JSON.parse(JSON.stringify(GTM_VIEW_USER_PHOTO));
  paramsGoogle.profile_id = data?.uid ?? 0;

  gtmTrack(GTM_AUTO, paramsGoogle);
};

/**
 * View user video event
 * @param {{uid: number}} data Event data. Uid - id of user
 */
export const userVideoViewEvent = (data) => {
  const paramsGoogle = JSON.parse(JSON.stringify(GTM_VIEW_USER_VIDEO));
  paramsGoogle.profile_id = data?.uid ?? 0;

  gtmTrack(GTM_AUTO, paramsGoogle);
};

/**
 * Like event
 * @param {{uid: number}} data Event data. Uid - liked user id
 */
export const likeUserEvent = (data) => {
  const paramsGoogle = JSON.parse(JSON.stringify(GTM_LIKE));
  paramsGoogle.profile_id = data?.uid ?? 0;

  gtmTrack(GTM_AUTO, paramsGoogle);
};

/**
 * Favorite event
 * @param {boolean} isFavorite Favorite or not
 * @param {{uid: number}} data Event data. Uid - favored user
 */
export const toggleFavoriteUserEvent = (isFavorite, data) => {
  const paramsGoogle = JSON.parse(
    JSON.stringify(isFavorite ? GTM_FAVORITE : GTM_DEL_FAVORITE)
  );
  paramsGoogle.profile_id = data?.uid ?? 0;

  gtmTrack(GTM_AUTO, paramsGoogle);
};

/**
 * Open chat with user
 * @param {{uid: number}} data Event data. Uid - chat opponent uid
 */
export const openChatEvent = (data) => {
  const paramsGoogle = JSON.parse(JSON.stringify(GTM_OPEN_CHAT));
  paramsGoogle.profile_id = data?.uid ?? 0;

  gtmTrack(GTM_AUTO, paramsGoogle);
};

/**
 * Success send of chat message
 * @param {{uid: number, attachImage: boolean, attachVideo: boolean, hasSticker: boolean}} data Event data.
 * @return {Promise<void>}
 */
export const sendChatSuccessMessageEvent = async (data) => {
  try {
    const paramsGoogle = JSON.parse(JSON.stringify(GTM_CHAT_MSG));
    paramsGoogle.profile_id = data?.uid ?? 0;
    gtmTrack(GTM_AUTO, paramsGoogle);

    if (data.attachImage) {
      const paramsGoogle = JSON.parse(JSON.stringify(GTM_CHAT_PHOTO));
      paramsGoogle.profile_id = data?.uid ?? 0;
      gtmTrack(GTM_AUTO, paramsGoogle);
    }

    if (data.attachVideo) {
      const paramsGoogle = JSON.parse(JSON.stringify(GTM_CHAT_VIDEO));
      paramsGoogle.profile_id = data?.uid ?? 0;
      gtmTrack(GTM_AUTO, paramsGoogle);
    }

    if (data.hasSticker) {
      const paramsGoogle = JSON.parse(JSON.stringify(GTM_STICKER_SEND));
      paramsGoogle.profile_id = data?.uid ?? 0;
      gtmTrack(GTM_AUTO, paramsGoogle);
    }
  } catch (e) {
    console.error(e.toString());
  }
};

/**
 * Failed send of chat message
 * @param {{uid: number, attachImage: boolean, attachVideo: boolean, hasSticker: boolean}} data Event data.
 * @return {Promise<void>}
 */
export const sendChatMessageFailedEvent = async (data) => {
  try {
    const paramsGoogle = JSON.parse(JSON.stringify(GTM_CHAT_MSG_FAIL));
    paramsGoogle.profile_id = data?.uid ?? 0;

    gtmTrack(GTM_AUTO, paramsGoogle);

    if (data.attachImage) {
      const paramsGoogle = JSON.parse(JSON.stringify(GTM_CHAT_PHOTO_FAIL));
      paramsGoogle.profile_id = data?.uid ?? 0;
      gtmTrack(GTM_AUTO, paramsGoogle);
    }

    if (data.attachVideo) {
      const paramsGoogle = JSON.parse(JSON.stringify(GTM_CHAT_VIDEO_FAIL));
      paramsGoogle.profile_id = data?.uid ?? 0;
      gtmTrack(GTM_AUTO, paramsGoogle);
    }

    if (data.hasSticker) {
      const paramsGoogle = JSON.parse(JSON.stringify(GTM_STICKER_SEND_FAIL));
      paramsGoogle.profile_id = data?.uid ?? 0;
      gtmTrack(GTM_AUTO, paramsGoogle);
    }
  } catch (e) {
    console.error(e.toString());
  }
};

/**
 * Send info about first spend on chat message
 * @param {{uid: number, userUid: number, spend: number, balance: number}} data Event data
 */
export const sendFirstChatMessageEvent = (data) => {
  if (!existCookie(CHAT_FIRST_MESSAGE_COOKIE)) {
    const userSimpleData = getUserSimpleCookieData();
    const paramsGoogle = JSON.parse(JSON.stringify(GTM_CHAT_FIRST));
    paramsGoogle.credits_value = +data?.spend;
    paramsGoogle.credits_rest =
      userSimpleData?.credits_rest ?? data?.balance ?? 0;
    paramsGoogle.age = userSimpleData?.age ?? 0;
    paramsGoogle.sex = userSimpleData?.sex ?? GTM_SEX_MALE;
    paramsGoogle.profile_id = data?.uid?.toString();
    paramsGoogle.userId = data?.userUid ?? "";
    gtmTrack(GTM_AUTO, paramsGoogle);
  }

  setCookie(CHAT_FIRST_MESSAGE_COOKIE, true, 365, true);
};

/**
 * Send to analytic info about success or failed sent message
 * @param {boolean} isSuccess Message send success or failed
 * @param {number} userUid Uid of current user
 * @param {{identity: string, type: number, media: Object, body: string, spend: number, balance: number}} message Message data
 * @return {Promise<void>}
 */
export const sendChatMessageEvent = async (isSuccess, userUid, message) => {
  const data = {
    uid: getChatOpponentUidFromIdentity(message?.identity ?? "", userUid),
    attachVideo: false,
    attachImage: false,
    hasSticker: false,
    isGift: false,
  };

  if (message?.type === CHAT_MESSAGE_TYPE_GIFT) {
    data.isGift = true;
  } else if (message?.type === CHAT_MESSAGE_TYPE_WINK) {
    data.hasSticker = true;
  } else if (message?.type === CHAT_MESSAGE_TYPE_MEDIA) {
    for (const [, value] of Object.entries(message?.media ?? {})) {
      if (value?.type === TYPE_VIDEO) {
        data.attachVideo = true;
      } else if (value?.type === TYPE_PHOTO) {
        data.attachImage = true;
      }
    }
  }

  data.hasSticker =
    data.hasSticker !== false
      ? data.hasSticker
      : message?.body?.length > 0 && hasTextEmoji(message?.body);

  if (isSuccess) {
    sendChatSuccessMessageEvent(data).catch((e) => {
      console.log(e.toString());
    });

    if (message?.type !== CHAT_MESSAGE_TYPE_WINK) {
      sendFirstChatMessageEvent({
        uid: data.uid,
        userUid,
        spend: message?.spend ?? 0,
        balance: message?.balance ?? 0,
      });
    }
  } else {
    sendChatMessageFailedEvent(data).catch((e) => {
      console.log(e.toString());
    });
  }
};

/**
 * Open mail with user
 * @param {{uid: number}} data Event data. Uid - user uid
 */
export const openMailEvent = (data) => {
  const paramsGoogle = JSON.parse(JSON.stringify(GTM_MAIL_OPEN));
  paramsGoogle.profile_id = data?.uid ?? 0;

  gtmTrack(GTM_AUTO, paramsGoogle);
};

/**
 * Send mail to user
 * @param {{uid: number}} data Event data. Uid - user uid
 */
export const sendMailEvent = (data) => {
  const paramsGoogle = JSON.parse(JSON.stringify(GTM_MAIL_SEND));
  paramsGoogle.profile_id = data?.uid ?? 0;

  gtmTrack(GTM_AUTO, paramsGoogle);
};

/**
 * Send mail to user failed
 * @param {{uid: number}} data Event data. Uid - user uid
 */
export const sendMailFailedEvent = (data) => {
  const paramsGoogle = JSON.parse(JSON.stringify(GTM_MAIL_SEND_FAIL));
  paramsGoogle.profile_id = data?.uid ?? 0;

  gtmTrack(GTM_AUTO, paramsGoogle);
};

/**
 * Send to analytics success purchase data
 * @param {Object} packageList List of payment packages
 * @param {Object} requestData Request object to payment service
 * @param {Object} responseData Response received from payment service
 */
export const successPurchaseEvent = (
  packageList,
  requestData,
  responseData
) => {
  if (
    !packageList ||
    !requestData ||
    !responseData ||
    packageList.length <= 0
  ) {
    return;
  }

  try {
    let packageItem = packageList?.filter(
      (item) => item && item.id === requestData.packageId
    )[0];

    if (!packageItem) {
      return;
    }

    const userSimpleData = getUserSimpleCookieData();
    const paramsGoogle = JSON.parse(JSON.stringify(GTM_SUCCESS_PURCHASE));
    paramsGoogle.transaction_id = responseData.invoiceId;
    paramsGoogle.value = parseFloat(packageItem?.price?.amount ?? "0");
    paramsGoogle.type = "next_order";
    paramsGoogle.userId = userSimpleData?.userId ?? "";
    paramsGoogle.age = userSimpleData?.age ?? 0;
    paramsGoogle.sex = userSimpleData?.sex ?? GTM_SEX_MALE;
    paramsGoogle.item_name = userSimpleData?.item_name ?? "Credits";
    paramsGoogle.items = [
      {
        item_name: paramsGoogle.item_name,
        price: paramsGoogle.value,
        quantity: packageItem?.creditAmount ?? 0,
        currency: packageItem?.price?.currency ?? "",
      },
    ];

    gtmTrack(GTM_AUTO, paramsGoogle, !responseData?.testPayment);
  } catch (e) {
    console.error(e.toString());
  }
};

/**
 * Send data to analytics about failed purchase
 * @param {Object} responseData Response received from payment service
 */
export const failedPurchaseEvent = (responseData) => {
  const userSimpleData = getUserSimpleCookieData();
  const paramsGoogle = JSON.parse(JSON.stringify(GTM_FAILED_PURCHASE));
  paramsGoogle.error_type = responseData?.declineReason
    ? responseData?.declineReason.toString()
    : paymentCBType.decline;
  paramsGoogle.userId = userSimpleData.userId ?? "";
  paramsGoogle.item_name = userSimpleData.item_name ?? "";

  gtmTrack(GTM_AUTO, paramsGoogle);
};

/**
 * Send info about success subscription on video stream
 * @param {uid: number} data Event data. Uid - user id
 */
export const streamSubscribeEvent = (data) => {
  const paramsGoogle = JSON.parse(JSON.stringify(GTM_SUBSCRIBE_ON_STREAM));
  paramsGoogle.profile_id = data?.uid ?? 0;

  gtmTrack(GTM_AUTO, paramsGoogle);
};

/**
 * Collect user click or other action on a site page
 * @param {string} event Event type identity
 * @param {null|Object} params Object with query params
 * @param {boolean} useTimeout If set true than execute action with timeout of 500ms
 * @return {boolean|null} Return false if event type not valid
 */
export const simplyClick = (event, params = null, useTimeout = true) => {
  if (isTrue(getCookie("DEV_MODE"))) {
    console.log("---trackClick", event, params, useTimeout);
  }

  if (window?.simplyTrack?.click && event) {
    if (useTimeout) {
      setTimeout(() => window.simplyTrack.click(event, params), 500);
    } else {
      window.simplyTrack.click(event, params);
    }
  }
};

/**
 * Track impression event
 */
export const routeHandleChange = () => {
  const url = window.location.href;
  if (
    typeof window.simplyTrack !== "undefined" &&
    typeof window.simplyTrack.impression === "function"
  ) {
    /**
     * Set timeout of execute current
     * function for remove concurrent of api requests
     */
    setTimeout(() => window.simplyTrack.impression(url), 1000);
  } else {
    /**
     * If analytic library still not upload then set interval for
     * track first impression in application
     */
    const analyticUploadInterval = setInterval(() => {
      if (
        typeof window.simplyTrack !== "undefined" &&
        typeof window.simplyTrack.impression === "function"
      ) {
        clearInterval(analyticUploadInterval);
        window.simplyTrack.impression(url);
      }
    }, 500);
  }
};
